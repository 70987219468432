import { createSlice } from "@reduxjs/toolkit";
import { Countdown } from "../../../interface";

const initialState: Countdown = {
  isLoading: true,
  launchValues: [],
  countdownComplete: false,
};

const countDownSlice = createSlice({
  name: "countdown",
  initialState,
  reducers: {
    getRemainingTime: (state) => {
      //  future time in ms
      let futureDate: Date = new Date(2023, 9, 30, 17, 30, 0);
      const futureTime = futureDate.getTime();
      const today = new Date().getTime();
      const t = futureTime - today;
      const oneDay = 24 * 60 * 60 * 1000;
      const oneHour = 60 * 60 * 1000;
      const oneMinute = 60 * 1000;
      // calculate all values
      let days = t / oneDay;
      days = Math.floor(days);
      let hours = Math.floor((t % oneDay) / oneHour);
      let minutes = Math.floor((t % oneHour) / oneMinute);
      let seconds = Math.floor((t % oneMinute) / 1000);
      // set values array
      function format(item: any) {
        if (item < 10) {
          return (item = `0${item}`);
        }

        return item;
      }

      state.launchValues = [
        format(days),
        format(hours),
        format(minutes),
        format(seconds),
      ];
      state.isLoading = false;
    },
  },
});
export const { getRemainingTime } = countDownSlice.actions;
export const countDownReducer = countDownSlice.reducer;
